<template lang="html">
  <div id="estructura-legal-page">

    <section class="container oversized-container main-section">
      <h1 class="text-center mb-3 title-st-1">ESTRUCTURA LEGAL</h1>
      <div class="row">
        <div class="col-lg-9 col-info">
          <h5 class="sm-subtitle">Seguridad y legalidad para el inversionista</h5>
          <p>
            Se creará una sociedad anónima promotora de inversión (SAPI DE CV) donde cada inversionista tendrá la seguridad y el respaldo que se trabajara conforme a las normas y principios que esta rige dentro de la organización se contemplan los siguientes puntos clave.
          </p>

          <br />
          <h5 class="sm-subtitle">Asamblea de accionistas</h5>
          <p>
            La cual está conformada por el corporativo SKYFUN PARKS SA DE CV con serie de acciones A y los inversionistas con serie de acciones B.
          </p>
          <p>
            <strong class="txt-blue">Serie de acciones A:</strong> tienen derecho a dividendos y tienen derecho a voz y voto.<br />
            <strong class="txt-blue">Serie de acciones B:</strong> tienen derecho a dividendos y no tienen derecho a voz y voto.
          </p>
          <p>
            Esto con el fin de mantener el control y tomar decisiones que ayuden al crecimiento del negocio Para mayor seguridad de la inversión la empresa tendrá siempre como mínimo el 30% de acciones del parque.
          </p>

          <br />
          <h5 class="sm-subtitle">Consejo administrativo</h5>
          <p>
            Encargado de la dirección de la operadora y de la toma de decisiones estratégicas, siendo uno de los principales actores, así mismo los inversionistas que tengan interés, compromiso, experiencia en el giro, podrán ser seleccionados para formar parte de este consejo siempre y cuando tenga un 10% o más de participación de este negocio.
          </p>

          <br />
          <h5 class="sm-subtitle">Gerencia general</h5>
          <p>
            Se encarga de las decisiones diarias del negocio, coordinado a las gerencias operativas y administrativas además de presentar los reportes operativos y de utilidad para la toma de decisiones estratégicas.
          </p>

          <br />
          <h5 class="sm-subtitle">Gerencia operativa y administrativa</h5>
          <p>
            Encargados de las actividades del día a día para que el parque funcione correctamente, solucionan problemas , asignan presupuestos para la nómina, compras, gastos y mantenimiento, reportan los resultados de las ventas diarias , verifican inventarios y mantiene los reportes financieros actualizados diariamente.
          </p>
        </div>

        <div class="col-lg-3 col-image">
          <img src="public/images/pages/estructura/image.png">
        </div>

        <div class="col-12 text-center col-images">
          <img src="public/images/pages/estructura/image-b1.png">
          <img src="public/images/pages/estructura/image-b2.png">
          <img src="public/images/pages/estructura/image-b3.png">
          <img src="public/images/pages/estructura/image-b4.png">
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
