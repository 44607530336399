<template lang="html">
  <div id="how-page">

    <section class="main-section">
      <div class="container oversized-container">
        <h1 class="mb-4 text-center title-st-1">¿CÓMO INVERTIR?</h1>

        <div class="mb-4 col-lg-9 px-0 mx-auto text-center">
          <h5 class="subtitle">Formas de pagos</h5>

          <p>
            Al momento de estar convencido de invertir y para separar sus acciones se deberá aportar el 20% del monto a adquirir y en un plazo no mayor a 15 días se debe de aportar el resto y firmar el contrato, en caso de incumplir con los plazos establecidos se pierde el anticipo.
          </p>
        </div>

        <div class="row">
          <div class="col-lg-6 col-info">
            <h4 class="mb-2 sm-subtitle">Cuanto se tarda operaciones en terminar el proyecto después de haber realizado la compra.</h4>
            <p>
              El local se encuentra legalmente rentado y en proceso de comprar todo lo necesario para su
              construcción, el tiempo que nos lleva la construcción es de alrededor de 4 meses a su vez la
              empresa cuanta con un tiempo de gracia equivalente a 2 meses más, pero el inversionista
              no pagara renta hasta que el lugar este completamente terminado La proyección del último
              semestre del 2022 y el primer semestre de 2023 es apertura como mínimo 5 unidades de
              negocio que como empresa manejamos, actualmente ya manejamos inversionistas en otros
              parques , pero dado el crecimiento tan acelerado , iniciamos este crowdfunding.
            </p>

            <br />
            <h4 class="mb-2 sm-subtitle">Beneficios adicionales para socios</h4>
            <p>
              Uno de los beneficios de este modelo de inversión es que cada socio es un embajador de la marca, es por eso que generamos beneficios adicionales para los socios inversionistas que quieran visitar la unidad de negocio en la cual invirtieron:
            </p>

            <div class="row mt-3 box-benefits">
              <div class="col-xl-4 my-1 col-benefit">
                <div class="box bg-1">
                  <p>20% de descuento en snack y restaurantes.</p>
                </div>
              </div>

              <div class="col-xl-4 my-1 col-benefit">
                <div class="box bg-2">
                  <p>20% de descuento en eventos y fiestas.</p>
                </div>
              </div>

              <div class="col-xl-4 my-1 col-benefit">
                <div class="box bg-3">
                  <p>20% de descuento en tiempo aire para uso de juegos.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-3 mt-lg-0 col-info">
            <h4 class="mb-2 sm-subtitle">Formas de pago de las utilidades</h4>
            <p>
              Transferencia o depósito bancario a la cuenta del socio.
            </p>

            <br />
            <h4 class="mb-2 sm-subtitle">Pagos de rendimientos</h4>
            <p>
              Se realizarán informes mensuales que integren la información total de ventas abarcando todos los rubros de nuestro negocio como, alimentos, fiestas, tiempo aire, pago de facturas, nomina, gastos diarios, cobros en efectivo, tarjetas y trasferencias etc.
            </p>
            <p class="mt-2">
              Tras la generación y presentación del informe mensual se le otorgara al inversionista las utilidades, mismas que se pagan cada mes, y se pagan el día 15 al 20 del mes siguiente en que se generó la utilidad, se recibe el 90% del monto ganado, el restante 10% de cada mes se entrega antes del 31 de enero del año siguiente y es el acumulado de todos los meses del año a pagar. Ese 10% es un fondo de reserva a utilizar en caso de los siguientes sucesos.
            </p>
            <ol class="mt-2">
              <li>En caso de que haya perdidas</li>
              <li>Para reposición de activos fijos dañados de alto valor, ejemplo pantallas, refrigeradores, etc.</li>
              <li>En casos de emergencia y se requiera de una inversión de capital inmediata, esta en una forma de asegurar la operación continua del negocio y la rentabilidad a largo plazo, ya que si no se tiene un fondo de emergencia cualquier eventualidad puede impactar negativamente a la unidad de negocio.</li>
            </ol>
          </div>
        </div>
      </div>

      <div class="mt-4 py-4 box-bg">
        <div class="container oversized-container">
          <div class="row">
            <div class="col-lg-6 py-2 col-info">
              <h3 class="mb-2 subtitle">Modelo de inversión colectivo</h3>
              <p>
                Es un modelo financiero que se basa en un Win Win, siendo socio de una empresa solida sin invertir grandes cantidades de dinero
              </p>

              <br />
              <h5 class="sm-subtitle">Tu Ganas</h5>
              <p>
                Al invertir en este modelo de negocio, pondrás tu dinero en una empresa que te va a generar rendimientos durante todo su tiempo de vida, sin necesidad de que trabajes en ella o le dediques tiempo directamente.
              </p>

              <br />
              <h5 class="sm-subtitle">Nosotros Ganamos</h5>
              <p>
                Ya que le damos más difusión a nuestras marcas, nos expandimos más rápido, por tanto, llegamos a más lugares y esto nos permite ser más rentables en corto, mediano y largo plazo y a su vez obtendremos mejores rendimientos y más poder de negociación en futuros parques.
              </p>
            </div>

            <div class="col-lg-6 py-2 col-info">
              <h3 class="mb-1 subtitle">Ventajas de nuestra propuesta de crowdfunding</h3>

              <h5 class="sm-subtitle">Inversión patrimonial</h5>
              <p>
                Al invertir en nuestros parques, te vuelves propietario de una parte accionaria de la empresa, esto significa que tienes respaldo en los activos tangibles del parque (juegos, climas, pantallas gigantes etc.)
              </p>

              <br />
              <h5 class="sm-subtitle">Plusvalía garantizada</h5>
              <p>
                Estas acciones tienen un valor inicial en esta etapa de preventa del proyecto, ya que cuando estemos terminados y completamente abiertos las acciones subirán inmediatamente de precio y seguirán aumentando conforme la unidad de negocio vaya generando utilidades.
              </p>

              <br />
              <h5 class="sm-subtitle">Garantía</h5>
              <p>
                Aunque somos una marca exitosa, para mayor seguridad del inversionista, estamos ofreciendo 2 garantías de satisfacción que son las siguientes:<br />
              </p>
              <p class="mt-1">
                Garantía de recompra de acciones con un 20% menos de lo que pago el inversionista, si en los primeros 6 meses de apertura del parque el inversionista decide abandonar el proyecto, sin preguntas la empresa recompra las acciones.
              </p>
              <p>
                Garantía de recompra de acciones al mes 12 regresando el 100% del monto invertido sin importar el motivo del por qué venden.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 container oversized-container">
        <div class="d-block text-center">
          <h3 class="mb-2 subtitle">¿Como funciona este modelo?</h3>
          <p>
            Es un modelo de inversión colectiva crowdfunding que se basa en las siguientes bases:
          </p>
        </div>

        <div class="row mt-3">
          <div class="col-lg-4 py-2 col-info">
            <h5 class="text-center sm-subtitle">Modelo de inversión colectivo</h5>
            <p>
              Socios inversionistas aportan capital para construir y operar una nueva unidad de negocios, a cambio reciben acciones de la empresa proporcionales a su inversión. Dichas acciones son patrimoniales y generan rendimientos en base a las utilidades de la unidad de negocio durante todo su tiempo de vida.
            </p>
          </div>

          <div class="col-lg-4 py-2 col-info">
            <h5 class="text-center sm-subtitle">Operado y administrado por expertos</h5>
            <p>
              El proyecto de construcción, operación, supervisión y administración de la unidad de negocio lo realizamos nosotros a través de un equipo entrenado y con experiencia para cumplir con todos los requerimientos de dicha unidad.
            </p>
          </div>

          <div class="col-lg-4 py-2 col-info">
            <h5 class="text-center sm-subtitle">Resultados mensuales</h5>
            <p>
              Entregamos a nuestros inversionistas reportes, que muestran los avances, ganancias y otros indicadores clave, de forma totalmente transparente ya que utilizamos software empresarial para administrar las unidades de negocio.
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
