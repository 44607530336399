<template lang="html">
  <div id="blog-page">

    <div class="loading-s1" v-if="isLoading">
      <div class="gooey">
        <span class="dot"></span>
        <div class="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <section class="container oversized-container articles-section" v-else>
      <div class="row mb-3">
        <div class="col-12 col-title" data-aos="fade-up">
          <h2 class="title-g1"><span>Proyectos</span></h2>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-4 mb-4 box-project-sample-1" v-for="(project, index) in parks" :key="index">
          <router-link class="placed-backg t-150 box-link" v-bind:style="{ backgroundImage: 'url('+project.imageUrl+')' }" :to="'/proyecto/'+$root._converToURL(project.name, project.id)">
            <img class="fake-image" src="public/images/pages/projects/base.png">

            <div class="inside-1">
              <h5 class="name">{{ project.name }}</h5>
              <!-- <h6>4,000 m</h6>
              <h6>10 jaulas</h6> -->
            </div>

            <div class="inside-2">
              <p>
                <span class="a">Explorar proyecto</span>
              </p>
            </div>
          </router-link>
        </div>

        <!-- <div class="col-sm-6 col-lg-4 col-article-sample-1" v-for="(project, index) in projects" :key="index">
          <router-link class="box-article" :to="'/proyectos/'+project.id">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+project.imageUrl+')' }">
              <img src="public/images/shared/empty.png">
            </div>

            <div class="box-descr">
              <h5>{{ project.title }}</h5>
              <div class="descr" v-html="project.body"></div>
            </div>
          </router-link>
        </div> -->

      </div>

      <!-- <div class="row mt-3">
        <div class="col-12 col-pagination-sample-1">
          <b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>
        </div>
      </div> -->
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      isLoading: true,

      parks:[],

      formSearch: {
        keywords: null
      },

      // projects: [
      //   { name: '555' },
      // ],
    }
  },

  methods: {
    getParks(){
      this.isLoading = true;
      axios.get(tools.url("/api/parks")).then((response)=>{
        this.parks = response.data;
        this.isLoading = false;
      }).catch((error)=>{
        console.error('ERROR');
        this.isLoading = false;
      });
    },

    onSubmit(event) {
      event.preventDefault();
    },

    linkGen(pageNum) {
      return {
        name: 'proyectos',
        query: { keywords: this.$route.query.keywords, page: pageNum }
      }
    },

    getRows: function(){
      axios.get(tools.url('/api/projects')).then((response)=>{
        this.projects = response.data;
      });
    }
  },

  mounted(){
    this.getParks();
    // this.getRows();
  },
}
</script>
