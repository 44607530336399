<template lang="html">
  <header id="header">
    <div class="header-content">
      <b-navbar toggleable="lg" type="dark" variant="">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo.svg" alt="">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="simple-item" to="/proyectos">Proyectos</b-nav-item>
              <b-nav-item class="simple-item" to="/empresa">Empresa</b-nav-item>
              <b-nav-item class="simple-item" to="/comunicados">Comunicados</b-nav-item>
              <b-nav-item class="simple-item" to="/estructura-legal">Estructura legal</b-nav-item>
              <b-nav-item class="simple-item" to="/como-invertir">¿Cómo invertir?</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/contacto">Contacto</b-nav-item>
              <!-- <b-nav-item-dropdown class="btn-simple" right>
                <template #button-content>
                  <span>Acceso a socios</span>
                </template>
                <b-dropdown-item to="#">Inicar sesión</b-dropdown-item>
                <b-dropdown-item to="#">Registrarse</b-dropdown-item>
              </b-nav-item-dropdown> -->
              <b-nav-item class="simple-item btn-simple" href="/admin#/login">Acceso a socios</b-nav-item>
              <!-- <b-nav-item class="nav-icon-circle" to="/cart"><span class="t-250 class="><i class="fal fa-shopping-cart"></i></span></b-nav-item> -->

              <li class="nav-item nav-icon-circle">
                <div class="nav-link justify-content-center">
                  <router-link class="t-250" to="/contacto"><i class="fal fa-envelope"></i></router-link>
                  <a class="t-250" target="_blank" href="https://www.facebook.com/SkyVentures-102651215877861"><i class="fab fa-facebook-f"></i></a>
                  <a class="t-250" target="_blank" href="https://www.instagram.com/skyventures.fun/"><i class="fab fa-instagram"></i></a>
                </div>
              </li>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </header>
</template>

<script type="text/javascript">
export default {
  mounted() {
  }
}
</script>
