<template lang="html">
  <div id="contact-page">

    <section class="container form-section">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="box-form">
            <h1 class="title">Contáctanos</h1>

            <b-form @submit="onSubmit">
              <b-form-group label="Tipo de contacto">
                <b-form-select v-model="form.subject" required>
                  <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                  <b-form-select-option value="Quiero invertir">Quiero invertir</b-form-select-option>
                  <b-form-select-option value="Cuento con un local que les puede interesar">Cuento con un local que les puede interesar</b-form-select-option>
                </b-form-select>
              </b-form-group>

              <div class="row">
                <b-form-group class="col-md-6" label="Nombre completo">
                  <b-form-input
                    v-model="form.name"
                    type="text"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="col-md-6" label="Correo electrónico">
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="col-md-6" label="Teléfono">
                  <b-form-input
                    v-model="form.phone"
                    type="text"
                    placeholder=""
                    maxlength="10"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="col-md-6" label="Empresa">
                  <b-form-input
                    v-model="form.company"
                    type="text"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="col-12" label="Estado">
                  <b-form-input
                    v-model="form.state"
                    type="text"
                    placeholder=""
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="col-12" label="Escribe tu asunto">
                  <b-form-textarea
                    v-model="form.msg"
                    placeholder=""
                    rows="4"
                    max-rows="4"
                    required
                  ></b-form-textarea>
                </b-form-group>

                <div class="col-12 mt-3 text-center">
                  <b-button type="submit" class="t-400 btn-send">ENVIAR</b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        subject: null,
        name: null,
        email: null,
        phone: null,
        company: null,
        msg: null,
      }
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      //var data=tools.params(form, this.form);
      axios.post(tools.url("/api/contact"), this.form).then((response)=>{
          // alert(response.data.response);
          this.makeToast('success', response.data.response, 'Mensaje enviado');

          for (let prop in this.form) {
            this.form[prop] = '';
          }
        }).catch( (error)=> {
        // console.log(error.response);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    }
  },
}
</script>
