import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', component: require(page+'home.vue').default },
      { path: '/empresa', component: require(page+'about-us/index.vue').default, meta:{title:"Empresa"}},
      { path: '/estructura-legal', component: require(page+'estructura-legal/index.vue').default, meta:{title:"Estructura legal"}},
      { path: '/como-invertir', component: require(page+'how/index.vue').default, meta:{title:"¿Cómo invertir?"}},
      { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"}},

      { path: '/comunicados', component: require(page+'blog/index.vue').default, meta:{title:"Comunicados"}},
      { path: '/comunicados/:id', component: require(page+'blog/detail.vue').default, meta:{title:"Comunicado"}},

      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de privacidad"}},
      { path: '/terminos-y-condiciones', component: require(page+'text-pages/terminos.vue').default, meta:{title:"Términos y condiciones"}},

      { path: '/proyecto/:id', component: require(page+'parks/description.vue').default, meta:{title:"Proyecto"}},

      { path: '/login', component: require(page+'user-access/login.vue').default, meta:{title:"Iniciar sesión"}},
      { path: '/recuperar-contrasena', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Recuperar contraseña"}},
      { path: '/registrarse', component: require(page+'user-access/register.vue').default, meta:{title:"Registrarse"}},

      { path: '/proyectos', component: require(page+'projects/index.vue').default, meta:{title:"Proyectos"}},
      { path: '/proyectos/:id', component: require(page+'projects/detail.vue').default, meta:{title:"Proyectos"}},

      

      { path: '/usuario', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
  			{
  				path: '/',
  				component: require(page+'usuario-cuenta/mis-datos.vue').default,
  				meta:{ title: 'Mis datos' }
  			},
  			{
  				path: 'contrasena',
  				component: require(page+'usuario-cuenta/contrasena.vue').default,
  				meta:{ title: 'Cambiar contraseña' }
  			},

  			// {
  			//   path: 'nueva-cotizacion',
  			//   component: require(page+'usuario-cuenta/nueva-cotizacion.vue'),
  			//   meta:{ title: 'Mis cotizaciones' }
  			// },
  			// {
  			//   path: 'mis-cotizaciones',
  			//   component: require(page+'usuario-cuenta/mis-cotizaciones.vue'),
  			//   meta:{ title: 'Mis facturas' }
  			// }
  	  	]
  		}

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "SKYVENTURES, Fun Projects", filter: (title)=>{ return title+" - SKYVENTURES, Fun Projects"; } }
);

// export {routes};
export default MyRouter;
