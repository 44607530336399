<template lang="html">
  <footer id="footer">
    <section class="newsletter-section">
			<h5>Enterate primero de nuestras nuevas publicaciones</h5>
      <h2>Sucríbete a nuestro Newsletter</h2>

      <b-form @submit.prevent="onSubmit">
        <b-form-group>
          <b-form-input
            v-model="form.email"
            type="email"
            required
            placeholder="Escribe tu correo"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-send">Enviar</b-button>
      </b-form>
    </section>

  	<section class="footer-section">
			<div class="container oversized-container">
				<div class="row">
					<div class="col-lg-2 col-xl-3 col-logo">
						<span class="box">
							<router-link to="/">
								<img src="public/images/logo-white.svg">
							</router-link>

							<p class="mt-3">
								<a class="t-150 btn-network" target="_blank" href="https://www.facebook.com/SkyVentures-102651215877861">
									<span><i class="fab fa-facebook-f"></i></span>
								</a>
								<a class="t-150 btn-network" target="_blank" href="https://www.instagram.com/skyventures.fun/">
									<span><i class="fab fa-instagram"></i></span>
								</a>
							</p>
						</span>
					</div>

					<div class="col-lg-10 col-xl-9">
						<div class="row">
							<div class="col-sm-6 col-lg-4 col-footer">
								<h5>Información General</h5>

								<p class="f-w-300"><router-link to="/">Inicio</router-link></p>
								<!-- <p class="mt-lg-2 f-w-300"><router-link to="/proyectos">Proyectos</router-link></p> -->
								<p class="mt-lg-2 f-w-300"><router-link to="/empresa">Empresa</router-link></p>
								<p class="mt-lg-2 f-w-300"><router-link to="/como-invertir">¿Cómo invertir?</router-link></p>
							</div>

							<div class="col-sm-6 col-lg-4 col-footer">
								<h5>Legal</h5>

								<p class="f-w-300"><router-link to="/estructura-legal">Estructura legal</router-link></p>
								<!-- <p class="mt-lg-2 f-w-300"><router-link to="/terminos-y-condiciones">Términos y condiciones</router-link></p> -->
								<p class="mt-lg-2 f-w-300"><router-link to="/aviso-de-privacidad">Aviso de Privacidad</router-link></p>
							</div>

							<div class="col-lg-4 col-footer col-copyright">
                <h5 class="mb-0">OFICINAS</h5>
								<p class="p-sm f-w-500">
									Jazmín #1165,<br />
									Colonia San Carlos, Guadalajara
								</p>

                <h5 class="mt-3 mb-0">TEL. Y WHATSAPP</h5>
								<p class="p-sm f-w-500">
									81 1372 5757
								</p>

								<h6 class="mt-3 mt-sm-4 copyr">Derechos reservados ©Skyventures</h6>
							</div>
						</div>
					</div>
				</div>
	  	</div>
  	</section>
  </footer>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: null
      },
    }
  },

  methods:{
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },
  }
}
</script>
