<template lang="html">
  <div id="home-page">

    <section class="container-fluid banner-section" data-aos="fade-in">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide  v-for="(banner, bdhInx) in banners.pc" :key="'bdhInx-'+bdhInx">
          <router-link class="d-block placed-backg" to="" v-bind:style="{ backgroundImage: 'url('+banner.imagen+')' }">
            <img src="public/images/pages/home/banner.png">
          </router-link>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide v-for="(banner, bmhInx) in banners.movil" :key="'bmhInx-'+bmhInx">
          <router-link class="d-block placed-backg" to="" v-bind:style="{ backgroundImage: 'url('+banner.imagen+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </router-link>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container oversized-container projects-section">
      <div class="row">
        <div class="col-12 col-title" data-aos="fade-up">
          <h2 class="title-g1"><span>Nuestros proximos proyectos</span></h2>
        </div>

        <div v-if="isloading" class="col-12 col-projects" data-aos="flip-down" style="text-align: center;">
          <div class="fa-3x"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        </div>

        <div v-else class="col-12 col-projects" data-aos="flip-down">
          <swiper class="swiper" :options="projectsOptions">
            <swiper-slide v-for="(ph, pInx) in parks" :key="'pInx-'+pInx">
              <div class="col-12 px-0 box-project-sample-1">
                <router-link class="placed-backg t-150 box-link" v-bind:style="{ backgroundImage: 'url('+ph.imageUrl+')' }" :to="'/proyecto/'+$root._converToURL(ph.name, ph.id)">
                  <img class="fake-image" src="public/images/pages/projects/base.png">

                  <div class="inside-1">
                    <h5 class="name">{{ ph.name }}</h5>
                    <!-- <h6>4,000 m</h6>
                    <h6>10 jaulas</h6> -->
                  </div>

                  <div class="inside-2">
                    <p>
                      <span class="a">Explorar proyecto</span>
                    </p>
                  </div>
                </router-link>
              </div>
            </swiper-slide>

         <!--    <swiper-slide v-for="(x, tempo) in 2" :key="'tempo-'+tempo">
              <div class="col-12 px-0 box-project-sample-1">
                <div class="placed-backg box-link" v-bind:style="{ backgroundImage: 'url(public/images/pages/projects/proximamente.png)' }">
                  <img class="fake-image" src="public/images/pages/projects/base.png">

                  <div class="inside">
                    <h5 class="mb-4 pb-3 name">Proximamente</h5>
                  </div>
                </div>
              </div>
            </swiper-slide> -->

            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>

      </div>
    </section>

    <section class="about-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-image" data-aos="fade-right">
            <a :href="banners.secundarios.url">
              <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+banners.secundarios.imagen+')' }"></div>
            </a>

          </div>

          <div class="col-md-7 col-lg-5 col-info" data-aos="fade-left">
            <h2 class="title-g1"><span>QUIENES SOMOS</span></h2>

            <h4 class="subtitle">SKY VENTURES es una empresa 100% Mexicana</h4>

            <p>
              Somos un grupo de emprendedores del entretenimiento que siempre esta en búsqueda de innovar, generar empleos y aportar socialmente. Contamos con mas de 10 proyectos actualmente en operación en diferentes ciudades de México y valuados en mas de 100 millones de pesos.
            </p>
            <p class="mt-3">
              Este sitio web tiene el objetivo de levantar capital para nuestros siguientes proyectos y sumar socios estratégicos para seguir creciendo.
            </p>
          </div>

          <div class="col-md-5 col-lg-1 pl-md-0 col-kids" data-aos="fade-up">
            <img src="public/images/pages/home/kids.png">
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container brands-section">
      <div class="row">
        <div class="col-12 col-title" data-aos="fade-up">
          <h2 class="title-g1"><span>Nuestras marcas de éxito</span></h2>
        </div>

        <div class="col-lg-12 col-brands" data-aos="fade-up">
          <swiper class="swiper" :options="brandsOptions">
            <swiper-slide v-for="(bh, bhInx) in brands" :key="'bhInx-'+bhInx">
              <a class="t-250 box-brand" target="_blank" :href="bh.urlSite">
                <p>
                  <img class="t-250" :src="bh.imgUrl">
                </p>
                <h6 class="t-150">{{ bh.name }}</h6>
              </a>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="container oversized-container blog-section">
      <div class="row">
        <div class="col-12 col-title" data-aos="fade-up">
          <h2 class="title-g1"><span>Comunicados</span></h2>
        </div>

        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="blogOption">
            <swiper-slide class="p-0" v-for="(blog, index) in blogs" :key="index">
              <div class="col-12 px-0 col-article-sample-1">
                <router-link class="box-article" :to="'/comunicados/'+blog.id">
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.imageUrl+')' }">
                    <img src="public/images/shared/empty.png">
                  </div>

                  <div class="box-descr">
                    <h5>{{ blog.title }}</h5>
                    <div class="descr" v-html="blog.body"></div>
                  </div>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      parks:[],
      banners: [
        /*{ imageUrl: 'public/images/pages/home/banner-1.jpg', device: 'pc' },
        { imageUrl: 'public/images/pages/home/banner-1-m.jpg', device: 'movil' },*/
      ],
      blogs: [
        /*{ id: 1, image_url: 'public/images/pages/projects/project-1.jpg', title: 'Título del comunicado', body: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat.</p>` },
        { id: 1, image_url: 'public/images/pages/projects/project-1.jpg', title: 'Título del comunicado', body: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat.</p>` },
        { id: 1, image_url: 'public/images/pages/projects/project-1.jpg', title: 'Título del comunicado', body: `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat.</p>` },*/
      ],

      projects: [
        { imgUrl: 'public/images/pages/projects/project-1.jpg', name: 'Sucursal Toluca' },
      ],


      brands: [
        { imgUrl: 'public/images/pages/home/brand-1.png', name: 'Dos sucursales en Monterrey y dos en Guadalajara', urlSite: 'https://www.facebook.com/Skyfun.centrosur/' },
        { imgUrl: 'public/images/pages/home/brand-2.png', name: 'Una sucursal en Monterrey y una en Guadalajara', urlSite: 'https://www.facebook.com/skykidsmty' },
        { imgUrl: 'public/images/pages/home/brand-3.png', name: 'Una sucursal en Monterrey y una en Guadalajara', urlSite: 'https://www.facebook.com/skygamersgdl' },
        { imgUrl: 'public/images/pages/home/brand-4.png', name: 'Una sucursal en Guadalajara', urlSite: 'https://www.facebook.com/SkyparkPuntoSur' },
        { imgUrl: 'public/images/pages/home/brand-5.png', name: 'Una sucursal en Monterrey y una en Guadalajara', urlSite: 'https://www.facebook.com/Skygamers.Sportbar.GDL' },
      ],

      /* == Carousel options == */
      bannersOption: {
        loop: false,
        speed:800,
        effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
      },

      projectsOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          1299: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 25
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 25
          },
        }
      },

      brandsOptions: {
        slidesPerView: 5,
        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          1299: {
            slidesPerView: 5,
            spaceBetween: 30
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 30
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          390: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          1: {
            slidesPerView: 1
          },
        }
      },

      blogOption: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 0,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          992: {
            slidesPerView: 3
          },
          576: {
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },
      // == ==
      isloading:true
    }
  },
  methods:{
      getParks(){
        this.isloading = true;
        axios.get(tools.url("/api/parks")).then((response)=>{
          this.parks = response.data;
          this.isloading = false;
        }).catch((error)=>{
          this.isloading = false;

        });
      },
      getBanners(){
        axios.get(tools.url("/api/banners")).then((response)=>{
          this.banners = response.data;
        }).catch((error)=>{

        });
      },
      getBlogs(){
        axios.get(tools.url("/api/blogs")).then((response)=>{
          this.blogs = response.data;
        }).catch((error)=>{

        });
      },
  },
  mounted() {
      this.getParks();
      this.getBanners();
      this.getBlogs();
  }
}
</script>
