<template lang="html">
  <div id="about-us-page">

    <section class="container oversized-container about-section">
      <h1 class="mb-2 title-st-1">QUIÉNES SOMOS</h1>

      <p>
        Somos una empresa 100% mexicana dedicada al entretenimiento infantil, así como de adultos, creamos franquicias y conceptos nunca vistos en México, creando lugares 100% vivenciales y diferentes, hechos con la mejor calidad y tecnología de punta, garantizando con esto un excelente ROI (retorno de inversión).
      </p>
    </section>

    <section class="vision-section">
      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-lg-3 col-title">
            <h4>Nuestra visión</h4>
          </div>

          <div class="col-lg-9 col-text">
            <p>
              Nuestra visión Llevar diversión a todo el país, fomentar la convivencia familiar por medio de nuestros parques, ya que son aptos para toda la familia Crear empleos para los jóvenes, brindando horarios aptos para los estudiantes de esa forma integrarlos a la economía.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="gallery-section">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(g, gaInx) in 20" :key="'gaInx-'+gaInx">
          <img :src="'public/images/pages/about-us/image-'+(gaInx+1)+'.jpg'">
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <section class="container oversized-container brands-section">
      <div class="row">
        <div class="col-12 col-title">
          <h2>NUESTRAS MARCAS DE ÉXITO</h2>
        </div>

        <div class="col-lg-12 col-brands">
          <swiper class="swiper" :options="brandsOptions">
            <swiper-slide v-for="(bh, bhInx) in brands" :key="'bh-'+bh">
              <a class="t-250 box-brand" target="_blank" :href="bh.urlSite">
                <p>
                  <img class="t-250" :src="bh.imgUrl">
                </p>
                <h6 class="t-150">{{ bh.name }}</h6>
              </a>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      brands: [
        { imgUrl: 'public/images/pages/home/brand-1.png', name: 'Dos sucursales en Monterrey y dos en Guadalajara', urlSite: 'https://www.facebook.com/Skyfun.centrosur/' },
        { imgUrl: 'public/images/pages/home/brand-2.png', name: 'Una sucursal en Monterrey y una en Guadalajara', urlSite: 'https://www.facebook.com/skykidsmty' },
        { imgUrl: 'public/images/pages/home/brand-3.png', name: 'Una sucursal en Monterrey y una en Guadalajara', urlSite: 'https://www.facebook.com/skygamersgdl' },
        { imgUrl: 'public/images/pages/home/brand-4.png', name: 'Una sucursal en Guadalajara', urlSite: 'https://www.facebook.com/SkyparkPuntoSur' },
        { imgUrl: 'public/images/pages/home/brand-5.png', name: 'Una sucursal en Monterrey y una en Guadalajara', urlSite: 'https://www.facebook.com/Skygamers.Sportbar.GDL' },
      ],

      // == Carousel options ==
      swiperOption: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 0,
        speed: 700,

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true
        },

        breakpoints: {
          1100: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          1: {
            slidesPerView: 2,
          },
        }
      },
      brandsOptions: {
        slidesPerView: 5,
        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          1299: {
            slidesPerView: 5,
            spaceBetween: 30
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 30
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          390: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          1: {
            slidesPerView: 1
          },
        }
      }
      // == ==
    }
  }
}
</script>
