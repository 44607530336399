<template lang="html">
  <div id="project-page">

    <section class="banners-section">
      <swiper class="swiper" :options="bannersOption">
        <swiper-slide >
          <div class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+row.imageUrl+')' }">
            <img src="public/images/pages/projects/base-2.png">

            <div class="inside">
              <h5>{{row.name}}</h5>
            </div>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <section class="container oversized-container info-section">
      <div class="row">
        <div class="box-content">
          <div class="col-md-6 col-lg col-info col-solicitada">
            <div class="box-label">
              <h5>Monto total de inversión solicitada</h5>
            </div>

            <div class="box-res">
              <h5>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row.total_investment_amount) }}  M.N.</h5>
            </div>
          </div>

          <div class="col-md-6 col-lg col-info col-minimo">
            <div class="box-label">
              <h5>Monto mínimo de inversión</h5>
            </div>

            <div class="box-res">
              <h5>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row.minimum_amount) }} M.N.</h5>
            </div>
          </div>

          <div class="col-md-6 col-lg col-info col-acciones">
            <div class="box-label">
              <!-- <h5>Número de acciones</h5> -->
              <h5>Acciones restantes (de {{row.total_actions}} %)</h5>
            </div>

            <div class="box-res">
              <h5>{{ row.total_actions_remaining }} %</h5>
            </div>
          </div>

          <div class="col-md-6 col-lg col-info col-rendimiento">
            <div class="box-label">
              <h5>Rendimiento anual esperado</h5>
            </div>

            <div class="box-res">
              <h5>{{row.expected_annual_return}}%</h5>
            </div>
          </div>

          <div class="col-12 col-lg col-info col-inversion" v-if="row.documentUrl != '' && row.documentUrl != null">
            <div class="box-label no-borders">
              <h5>Proyecto de inversión</h5>
            </div>

            <div class="box-res no-borders">
              <a class="t-150 btn-pdf" target="_blank" :href="row.documentUrl">Descargar PDF</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="extra-info-section">
      <div class="container oversized-container">
        <div class="row justify-content-center">
          <!-- <div class="col-sm-6 col-md-4 col-lg col-info" v-for="(p, pInx) in extrInfo" :key="'pInx-'+pInx">
            <p class="mb-1 mb-lg-3">
              <img :src="p.imageUrl">
            </p>
            <h6>
              {{ p.text }}
            </h6>
          </div> -->

          <div class="col-sm-6 col-md-4 col-lg col-info" v-html="row.differentiator_one"></div>
          <div class="col-sm-6 col-md-4 col-lg col-info" v-html="row.differentiator_two"></div>
          <div class="col-sm-6 col-md-4 col-lg col-info" v-html="row.differentiator_three"></div>
          <div class="col-sm-6 col-md-4 col-lg col-info" v-html="row.differentiator_four"></div>
          <div class="col-sm-6 col-md-4 col-lg col-info" v-html="row.differentiator_five"></div>
     
        </div>
      </div>
    </section>

    <section class="container oversized-container extra-description-section">
      <div class="row">
        <div class="col-lg-6 col-text" v-html="row.differentiator_center">
         <!--  <p>
            Todo se maneja por medio de software, NFC y lectoras de NFC, el cliente compra una tarjeta y la recarga con el monto que él quiera, después cada juego tiene una lectora, lo usan y se les descuenta del saldo que tiene la tarjeta. El saldo se recarga manualmente en taquillas o por medio de los cajeros automáticos que cargan saldo y dispensan tarjetas, toda el área de juego es cashless (es decir se maneja con tarjetas NFC).
          </p>

          <p class="mt-3">
            Contaremos con un área de snack y restaurante con comida muy variada desde papas con salsa hasta cortes de carne y toda esa área es amenizada con una pantalla gigante de 15 m2 es decir 5 x 3 metros de largo y alto respectivamente, la zona de restaurante cuenta con asientos para 350 personas.
          </p>

          <p class="mt-3">
            Otras de nuestras principales fuentes de ingresos son las fiestas infantiles contaremos con 2 salones de fiestas con cupo de 120 personas cada salón y también tienen pantallas gigantes, pero esas son de 6 m2 a su vez contamos con 2 áreas especificas donde se realizarán las fiestas minis que son muy populares.
          </p> -->
        </div>

        <div class="col-lg-6 mt-3 mt-lg-0 col-table" v-html="row.differentiator_right">
          <!--<h6 class="title-s1">Eventos Panorámicos</h6>
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <td>Alimento</td>
                  <td>50</td>
                  <td>80</td>
                  <td>100</td>
                  <td>120</td>
                  <td>Extra</td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Chilidog<br />Nuggets</td>
                  <td>$14,000</td>
                  <td>$20,000</td>
                  <td>$24,000</td>
                  <td>$27,600</td>
                  <td>$280</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h6 class="title-s1">Eventos Mini</h6>
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <td>Alimento</td>
                  <td>Personas</td>
                  <td colspan="2">Lunes a Domingo</td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Pizza</td>
                  <td>10</td>
                  <td>$3,500</td>
                  <td>$350</td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </div>

        <div class="col-12 mt-3 col-graph">
          <h5 class="title-s1">En que se invierte el capital</h5>
          <div v-html="row.differentiator_left"></div>
         <!--  <img src="public/images/pages/projects/graph.png"> -->
        </div>
      </div>
    </section>

    <section class="container oversized-container description-section">
      <!-- <h2 class="mb-4 s-title">Proyecto</h2>

      <h5>Nuestra vision</h5>
      <p>
        Nuestra visión Llevar diversión a todo el país, fomentar la convivencia familiar por medio de nuestros parques, ya que son aptos para toda la familia Crear empleos para los jóvenes, brindando horarios aptos para los estudiantes de esa forma integrarlos a la economía.
      </p>

      <br />
      <h5>Ventajas competitivas</h5>
      <ul>
        <li>Importamos juegos y tecnología de varios países del mundo, eso nos garantiza un diferenciador gigante contra la competencia.</li>
        <li>Usamos tecnología en software y business intelligence que nos ayudan a optimizar ventas y utilidades en nuestras unidades de negocio.</li>
        <li>Usted cuenta con garantía de satisfacción por 1 año o la devolución de su dinero.</li>
        <li>Al comprar acciones de la empresa propietaria del parque, es usted legalmente dueño del parque.</li>
      </ul>

      <p>
        Las identidades de nuestras marcas son únicas y se diferencian muchísimo de la competencia, cuidamos cada detalle y nuestra clientela ya sabe que nuestras unidades de negocio están padrísimas y brindamos experiencias, que hacen feliz a chicos y grandes, y eso se refleja en ventas.<br />
        <br />
        Nuestras marcas están expansión, con miras de abrir en un plazo corto varias unidades de negocio en las principales ciudades del país, esto realmente representa una oportunidad de inversión en la cual podrá obtener muy buenos rendimientos en un futuro.
      </p>

      <br />
      <h5>Las siguientes franquicias de entretenimiento fueron creadas y son administradas por nuestra empresa:</h5>
      <ul>
        <li>Skyfun (2 sucursales en Monterrey y 2 en Guadalajara)</li>
        <li>Skykids (1 sucursal en Monterrey y 1 en Guadalajara)</li>
        <li>Skygamers play zone (1 sucursal en Monterrey y 1 en Guadalajara)</li>
        <li>Skypark (en sucursal en Guadalajara)</li>
        <li>Skygamers sport bar (1 sucursal en Monterrey y 1 en Guadalajara)</li>
      </ul>

      <h5>Modelo de inversión colectivo</h5>
      <p>
        Es un modelo financiero que se basa en un Win Win, siendo socio de una empresa solida sin invertir grandes cantidades de dinero.
      </p>
      <h6>Tu Ganas</h6>
      <p class="mt-1">
        Al invertir en este modelo de negocio, pondrás tu dinero en una empresa que te va a generar rendimientos durante todo su tiempo de vida, sin necesidad de que trabajes en ella o le dediques tiempo directamente.
      </p>

      <h6 class="mt-1">Nosotros Ganamos</h6>
      <p>
        Ya que le damos más difusión a nuestras marcas, nos expandimos más rápido, por tanto, llegamos a más lugares y esto nos permite ser más rentables en corto, mediano y largo plazo y a su vez obtendremos mejores rendimientos y más poder de negociación en futuros parques.
      </p>

      <br />
      <h6>¿Como funciona este modelo?</h6>
      <p>
        Es un modelo de inversión colectiva crowdfunding que se basa en las siguientes bases:
      </p>

      <br />
      <h6>Modelo de inversión colectivo</h6>
      <p>
        Socios inversionistas aportan capital para construir y operar una nueva unidad de negocios, a cambio reciben acciones de la empresa proporcionales a su inversión. Dichas acciones son patrimoniales y generan rendimientos en base a las utilidades de la unidad de negocio durante todo su tiempo de vida.
      </p>

      <br />
      <h6>Operado y administrado por expertos</h6>
      <p>
        El proyecto de construcción, operación, supervisión y administración de la unidad de negocio lo realizamos nosotros a través de un equipo entrenado y con experiencia para cumplir con todos los requerimientos de dicha unidad.
      </p>

      <br />
      <h6>Resultados mensuales</h6>
      <p>
        Entregamos a nuestros inversionistas reportes, que muestran los avances, ganancias y otros indicadores clave, de forma totalmente transparente ya que utilizamos software empresarial para administrar las unidades de negocio.
      </p>

      <br />
      <h5>Ventajas de nuestra propuesta de crowdfunding</h5>
      <h6>Inversión patrimonial</h6>
      <p>
        Al invertir en nuestros parques, te vuelves propietario de una parte accionaria de la empresa, esto significa que tienes respaldo en los activos tangibles del parque (juegos, climas, pantallas gigantes etc.)
      </p>

      <br />
      <h6>Plusvalía garantizada</h6>
      <p>
        Estas acciones tienen un valor inicial en esta etapa de preventa del proyecto, ya que cuando estemos terminados y completamente abiertos las acciones subirán inmediatamente de precio y seguirán aumentando conforme la unidad de negocio vaya generando utilidades.
      </p>

      <br />
      <h6>Garantía</h6>
      <p>
        Aunque somos una marca exitosa, para mayor seguridad del inversionista, estamos ofreciendo 2 garantías de satisfacción que son las siguientes:
      </p>
      <ol>
        <li>
          Garantía de recompra de acciones con un 20% menos de lo que pago el inversionista, si en los primeros 6 meses de apertura del parque el inversionista decide abandonar el proyecto, sin preguntas la empresa recompra las acciones.
        </li>
        <li>
          Garantía de recompra de acciones al mes 12 regresando el 100% del monto invertido sin importar el motivo del por qué venden.
        </li>
      </ol>

      <h6>Ubicación</h6>
      <p>
        Cuenta con una ubicación inmejorable ya que estamos junto a un Walmart que cuenta con un estacionamiento gigante y una afluencia de gente enorme, otros de nuestros vecinos será Planet Gym, gimnasio de bajo costo que también atrae muchísima gente. Así como el mercado, la central de autobuses y la plaza de la tecnología están detrás de nosotros, el centro comercial, El Gran Patio está a unos cuantos minutos, estamos en un lugar céntrico.
      </p> -->

      <div class="box-map">
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d941.5115005685053!2d-99.6402562!3d19.2803656!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd89892be4d417%3A0xff778e56410713db!2sAv.%20Paseo%20Tollocan%20600%2C%20Progreso%2C%2050150%20Toluca%20de%20Lerdo%2C%20M%C3%A9x.%2C%20M%C3%A9xico!5e0!3m2!1ses!2sca!4v1658412006962!5m2!1ses!2sca" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->


        <GmapMap
            :center="{lat:row.lat, lng:row.lng}"
            :zoom="16"
            map-type-id="terrain"
            style=" height: 600px"
          >
           <GmapMarker
            :position="{lat:row.lat, lng:row.lng}"
            :clickable="true"
            :draggable="false"
            v-if="row.lat != null && row.lng != null"/>
        </GmapMap>

      </div>

      <div class="box-button">
        <router-link class="t-150 btn-invest" to="/contacto">Quiero invertir</router-link>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: [
        { imageUrl: 'public/images/pages/projects/banner-1.jpg' },
        { imageUrl: 'public/images/pages/projects/banner-1.jpg' },
      ],

      extrInfo: [
        { imageUrl: 'public/images/pages/projects/icon-1.png', text: 'Contaremos con maquinitas que dan ticket (minimo 50) con un precio que va desde los 10 a los $17 pesos por juego, cada juego no dura más de 4 minutos.' },
        { imageUrl: 'public/images/pages/projects/icon-2.png', text: 'Contaremos con maquinas de realidad virtual de 2 a 3 dichas máquinas que tendrán un costo de $50 pesos, cada juego dura de 5 a 8 minutos.' },
        { imageUrl: 'public/images/pages/projects/icon-3.png', text: 'Contaremos con una pista de patinar de alrededor de 300 m2, su costo será de $50 pesos por tiempo ilimitado.' },
        { imageUrl: 'public/images/pages/projects/icon-4.png', text: 'Contaremos con un laberinto gigante con costo de $100 pesos por tiempo ilimitado.' },
        { imageUrl: 'public/images/pages/projects/icon-5.png', text: 'Contaremos con un área de trampolines super completo y grande con un costo de $150 pesos por tiempo ilimitado.' },
      ],

      // == Carousel options ==
      bannersOption: {
        loop: false,
        speed:800,
        effect: 'fade',

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
      },
      // == ==
      id:null,
      row:{}
    }
  },
  methods:{
      getPark(){
          axios.get(tools.url("/api/parks/"+this.id)).then((response)=>{
            this.row = response.data;
          }).catch((error)=>{
          });

      }
  },
  mounted(){
    this.id = this.$route.params.id;
    this.id = this.$root._getURLID(this.$route.params.id);
    this.getPark();
  }
}
</script>
