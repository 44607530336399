<template lang="html">
  <div id="text-page">
    <div class="container">
      <h1 class="page-title">Aviso de Privacidad</h1>

      <p>
        En cumplimiento con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares y con la finalidad de asegurar la protección y privacidad de los datos personales, así como regular el acceso, rectificación, oposición y cancelación de estos SKYVENTURES – FUN PROJECTS en adelante http://skyventures.site/ establece lo siguiente:<br />
        <br />
        Este aviso es relacionado al uso de la App para dispositivos móviles con sistema operativo IOS y la APP para dispositivos móviles con sistema operativo Android.
      </p>

      <br />
      <h5>DATOS PERSONALES</h5>
      <p>
        La información solicitada y recabada o recibida por SKYVENTURES – FUN PROJECTS,  ya sea en forma física, electrónica o cualquier otro medio, consistirá únicamente en el correo electrónico.
      </p>

      <br />
      <h5>COOKIES</h5>
      <p>
        Nuestro sitio web almacena información acerca de los usuarios que lo visitan y sus diferentes secciones a través de cookies e IPS o archivos temporales, los cuales se emplean únicamente con el propósito de mejorar la experiencia y velocidad de navegación del usuario.
      </p>

      <br />
      <h5>DERECHOS ARCO (ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN)</h5>
      <p>
        En particular, la ley otorga a los titulares de los datos personales el derecho a acceder, rectificar, y cancelar su información personal en posesión de terceros, así como oponerse a su uso. A estos se les conoce como derechos ARCO.<br />
        <br />
        Recuerda que para que puedas ejercer tus derechos ante el responsable, deberás demostrar que tú eres el titular de los datos o, en caso de que lo hagas a través de representante, deberás acreditar esta situación. Esto está pensado para que NADIE más que tú o tu representante, puedan decidir el uso que se le dará a tus datos personales; es por tu protección y la de tu información.<br />
        <br />
        En caso de que ejerzas tus derechos a través de un representante, podrás acreditarlo de las siguientes maneras:
      </p>
      <ul>
        <li>Presentando un instrumento público en el que conste tal situación.</li>
        <li>Mediante carta poder firmada ante dos testigos.</li>
        <li>A través de la declaración en comparecencia del titular.</li>
      </ul>

      <p>
        Podrás solicitar el ejercicio de sus derechos enviándonos un correo electrónico a la dirección siguiente: legal@skyventures.site
      </p>

      <br />
      <h5>AUTORIZACIÓN</h5>
      <p>
        Si tu como usuario, proveedor, cliente o prestador de servicios nos proporcionas tus datos personales se entiende que has leído, entendido y aceptado el presente aviso de privacidad, así como la transferencia de tus datos para los fines señalados y no has manifestado oposición con el mismo.
      </p>

      <br />
      <h5>FECHA DE ACTUALIZACION</h5>
      <p>
        Este aviso fue actualizado por última vez el día 6 de JUNIO de 2022 más puede cambiar en cualquier momento y sin previo aviso más que el publicado en nuestra página web http://skyventures.site/
      </p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
